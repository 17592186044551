.form-footer {
  margin: -20px;
  padding: 15px 20px;
  margin-top: 0px;
  background: #f2f2f2;
  border-top: 1px solid #ddd;
}

.form-footer.small {
  padding: 7px;
}
.form-footer:empty {
  display: none;
}
.form-footer ul.language-switcher {
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden;
  *zoom: 1;
}
.form-footer ul.language-switcher li {
  list-style-image: none;
  list-style-type: none;
  margin-left: 0;
  white-space: nowrap;
  float: left;
  padding-left: 4px;
  padding-right: 4px;
}
.form-footer ul.language-switcher li:first-child {
  padding-left: 0;
}
.form-footer ul.language-switcher li:last-child {
  padding-right: 0;
}
.form-footer ul.language-switcher li.last {
  padding-right: 0;
}
.form-footer ul.language-switcher li {
  line-height: 28px;
}
.form-footer ul.language-switcher li a img {
  height: 16px;
  border: none;
}

input.multilang, textarea.multilang {
  background-repeat: no-repeat !important;
  background-position: right;
  padding-right: 24px !important;
}
input.multilang.bordered, textarea.multilang.bordered {
  background-position: right 10px top 12px;
}

.multilang-editor .fr-box.fr-basic .fr-element {
  background-repeat: no-repeat !important;
  background-position: right 10px top 12px;
}

.impersonate-frame {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 10000000;
  -moz-box-shadow: inset 0 0 0 2px #F44336;
  -webkit-box-shadow: inset 0 0 0 2px #F44336;
  box-shadow: inset 0 0 0 2px #F44336;
}

.ui-datepicker {
  z-index: 1033 !important;
}

.coder-modal__bg {
  z-index: 1031;
}

.coder-modal__scroller {
  z-index: 1032;
}

.modal-inner-container {
  padding: 20px;
  background: white;
}

.coder-radio {
  margin-top: 9px;
  margin-bottom: 9px;
}
.coder-radio input[type=radio] {
  display: none;
}
.coder-radio input[type=radio] + span:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  border: 4px solid;
  border-color: inherit;
  width: 0;
  height: 0;
  border-radius: 100%;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transform: scale(0);
}
.coder-radio input[type=radio]:checked + span:after {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  transform: scale(1);
}
.coder-radio label {
  min-height: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.coder-radio label span {
  top: 1px;
  left: 0;
  cursor: pointer;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: 2px solid #607D8B;
  margin-right: 10px;
  position: relative;
}
